import React from 'react'
import Copy from 'components/Copy'
import Image from 'components/Image'
import Row from 'components/Row'
import Text from 'components/Text'
//import ResourceForm from 'components/ResourceForm'
import GatedResource from 'templates/GatedResource'
import boldImage from './images/bold.png'

export default function ResourceFreeClass() {
  const title = 'Free class'

  return (
    <GatedResource
      title="Try a Bold class on us"
      entity="video"
      //form={ResourceForm}
      resource={title}>
      <Row>
        <Copy>
          <Text element="p">Interested in joining Bold, but want to try a class first?</Text>
          <Text element="p">No sweat!</Text>
          <Text element="p">
            We will email you a class to try so you can get moving. No sign up needed.
          </Text>
        </Copy>
      </Row>
      <Image src={boldImage} alt="Bold" />
    </GatedResource>
  )
}
